body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  margin: 0;
}

@media screen and (min-width: 960px) {
  body {
    overflow: hidden;
  }
}

.loading-logo {
  animation: loading-logo 1s infinite;
}

@keyframes loading-logo {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}

@media print {
  @page {
    size: landscape;
  }
}
